import React from 'react'
import { Helmet } from 'react-helmet'
import HeroImage from '../components/hero/heroImage';
import Section from '../components/layout/section';
import TeamList from '../components/team/teamList';
import Clients from '../components/clients/clients';

const TeamUndPartner = ({...props}) => {
  const data = props.data.contentfulTeamUndPartner;
  console.log("TeamUndPartner props: ",props);
    return ( 
        <div>
          <Helmet title={data.title} />
          <div className="wrapper">
          <Section small>
            <HeroImage image={data.image} title="Teamplayer" /></Section>
            <Section>
              <TeamList useSlider={false} />
            </Section>
          </div>
        </div>
      );
}
 
export default TeamUndPartner;


export const pageQuery = graphql`
  query TeamUndPartnerQuery {
    contentfulTeamUndPartner {
      title
      image {
        fluid(maxWidth: 1180,quality: 90, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`